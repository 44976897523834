/**
 * Constructs a left-navigation hierarchy of POJO via the
 * front-matter of a markdown file.
 *
 * TODO: parameterize front-matter key values
 * The markdown file contains keys that denote that files
 * place in the navigation hierarchy. To configure the
 * markdown front-matter for parsing, use the following
 * front-matter keys:
 *
 * 1. `order` <Number>: configures the item's order on the list
 *    of same level
 * 1. `parent` <String>: identifies the parent item for the current
 *    item. If not given, then the item will be on the root
 * 1. `index` <Boolean>: identifies an item if it is an index file
 *    that acts as the group identifier, and if it should
 *    render to show top level details for a group of items.
 *    If index is false, the file's front-matter content
 *    will be used to build the hierarchy but the content
 *    itself will not be rendered or reachable through the left
 *    navigation.
 * 1. `index_parent` <String>: identifies the parent for the
 *    index file. This is separate from the `parent` option,
 *    which is meant for non-index markdowns. `index_parent`
 *    is for the index. We make this distinction
 *    because a markdown can optionally have an `index_parent` to
 *    mark it's place in the hierarchy one level higher, versus
 *    a `parent` which also acts to groups together all the
 *    items under a list that is part of the `index`.
 * 1. `index_list` <Boolean>: Whether to show the index markdown
 *    in the list of items it groups itself.
 */

import flow from "lodash/fp/flow"
import map from "lodash/fp/map"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"
import forEach from "lodash/forEach"

import compact from "lodash/fp/compact"
import invokeMap from "lodash/fp/invokeMap"
import mapKeys from "lodash/fp/mapKeys"
import some from "lodash/fp/some"
import keys from "lodash/fp/keys"
import pickBy from "lodash/fp/pickBy"

export default function construct(items) {
  const tree = new Tree()
  items.forEach(e => {
    const node = new Node(e)
    if (!e["exclude"]) {
      tree.addNode(node)
    }
  })
  return tree.toObject()
}

/**
 * Groups:
 *  case 1: clicking group name simply expands the items under that group
 *  case 2: clicking group name goes to an index page and that index page is part of the children list
 *  case 3: clicking group name goes to an index page and that index page is not part of the children list
 *
 *  case 1 method: add all child files with same `parent` value and designate one with `index_order` and `index_parent`
 *    and `index` to `false`.
 *  case 2 method: add all child files with same `parent` value and designate one with `index_list`, `index_parent`
 *    and `index_order` values, and `index` to true.
 *  case 3 method: add all child files with same `parent` value and designate one with `index_order` and `index_parent`
 *    and `index` to `true`.
 */

class Node {
  constructor(props = {}) {
    this._props = props
    this._parent = props["parent"]
    this._children = []
  }

  isEqual(node) {
    return isEqual(this._props, node._props)
  }

  addFirstChild(node) {
    if (this._children[0]) {
      this._children.unshift(node)
    } else {
      this._children[0] = node;
    }
  }

  addChild(node, ignoreOrder = false) {
    const child = cloneDeep(node)
    if (ignoreOrder) {
      this._children.push(child)
      return
    }

    if (this.isIndex(child) && !child._props["order"] && this.isEqual(child)) {
      this.addFirstChild(child)
      return
    }

    const index = child._props["index_order"] || child._props["order"]
    if (index) {
      this._children[index] = child
      return
    }

    this._children.push(child)
  }

  isIndex(node) {
    const index = node ? node._props["index"] : this._props["index"]
    return index !== null
  }

  toObject() {
    return {
      ...this._props,
      _children: flow(
        map(e => (e ? e.toObject() : null)),
        compact
      )(this._children),
    }
  }

  resolveParent() {
    return this.isIndex() ? this._props["index_parent"] : this._parent
  }
}

class Tree {
  constructor(root = null, props = {}) {
    this._root = root || new Node(props)
    this._pending = new Node()
  }

  addNode(node) {
    this.walkOneUp(this._pending, node)
    this.walkDown(this._root, node) || this.walkDown(this._pending, node) || this._pending.addChild(node, true)
  }

  addToRoot(node) {
    const hasParent = !!node._parent
    const hasIndexParent = !!node._props["index_parent"]
    const isIndex = node.isIndex()

    // if it has no parent then stick it into root child
    // only if it has no index parent
    if (!hasParent && !hasIndexParent) {
      this._root.addChild(node)
      return true
    }

    if (hasParent && !hasIndexParent && isIndex) {
      this._root.addChild(node)
      return true
    }

    return false
  }

  /**
   * We want to see if any orphaned nodes in pending has a
   * parent of `node` by walking up from `start`'s children
   * to attach children to `node`
   * @param {Node} start a list of
   * @param {Node} node the node to connect childrens to
   */
  walkOneUp(start, node) {
    const children = start._children
    if (!children.length) {
      return false
    }

    const isIndex = node.isIndex()

    const parent = isIndex
      ? node._parent
      : node._props["title"] || node._props["key"]
    const adds = this.indexOfs(children, parent)

    adds.forEach(add => {
      node.addChild(children[add])
      children[add] = null
    })

    if (node._props["index_list"]) {
      const self = cloneDeep(node)
      self._children = []
      node.addChild(self)
    }

    return !!adds.length
  }

  indexOfs(children, val) {
    return flow(
      invokeMap("resolveParent"),
      mapKeys(k => k),
      pickBy(v => v === val),
      keys
    )(children)
  }

  /**
   * We have parent and we search down the tree
   * where we can connect node
   */
  walkDown(start, node) {
    if (this.addToRoot(node)) {
      return true
    }
    if (!start) {
      return false
    }

    const children = start._children || []
    const parent = node.resolveParent()

    let added
    if (parent && children.length) {
      added = flow(
        map(e => {
          if (!e) {
            return false
          }

          if (e._props["title"] === parent || e._props["key"] === parent) {
            e.addChild(node)
            return true
          }
        }),
        some(Boolean)
      )(children)
    } else {
      return false
    }

    if (!added) {
      forEach(children, e => !(!!e && (added = this.walkDown(e, node))))
    }
    return added
  }

  toObject() {
    return this._root._children.map(e => e.toObject())
  }
}
