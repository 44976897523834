import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import construct from "../../../components/leftnav/utilities/construct-nav"
import { head } from "lodash"
import Nav from "../../../components/leftnav/nav"

export default function TourLeftNav() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { permalink: { regex: "/tour/" } } }) {
        nodes {
          frontmatter {
            permalink
            title
            parent
            order
            index
          }
        }
      }
    }
  `)

  const items = data.allMdx.nodes.map(e => e.frontmatter)
  const navData = construct(items)

  return <Nav data={head(navData)} />
}
