import React from 'react';
import SEO from '../../components/seo';
import LeftNav from '../../page-components/tour/left-nav/left-nav';
import Footer from '../../components/footer/footer';
import styles from './tour.module.scss';
import { Container } from 'react-bootstrap';
import MdxProvider from '../../utils/mdx-provider';

const main = ({ children, location }) => {
  return (
    <MdxProvider>
      <SEO title={`Dashbot`} />
      <div className={`${styles.banner}`}>
        <Container>
          <h3>Tour</h3>
        </Container>
      </div>
      <Container className={`${styles.flexWrapper}`}>
        <LeftNav />
        <main className={`${styles.main} `}>{children}</main>
      </Container>
      <Footer />
    </MdxProvider>
  );
};

export default main;
