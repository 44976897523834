import React from "react"
import { Button, Collapse, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import styles from "./nav.module.scss"

function NavItem({ item, location, level }) {
  const current = location && location.pathname && location.pathname.split('/')[level]
  const match = current === item.permalink.split("/")[level]

  const [open, setOpen] = React.useState(match)

  const handleOpen = async e => {
    e.preventDefault()
    await setOpen(!open)
  }

  return (
    <React.Fragment key={item.title}>
      <li className={`${styles.menuItem} ${styles.menuList}`}>
        <span className={`d-flex justify-content-between`}>
          <Link
            className={`${styles.menuItemLink} d-inline-flex`}
            to={item.permalink}
          >
            {item.title || item["nav_title"]}
          </Link>
          <button className={`${styles.menuItemButton}`} onClick={handleOpen}>
            <i
              className={`${
                open ? styles.rotateDownTransition : styles.rotateUpTransition
              } bx bx-chevron-right`}
            />
          </button>
        </span>
        <Collapse in={open}>
          <NavGroup
            items={item._children}
            className={`${styles.menuItemBody}`}
            level={level}
            location={location}
          />
        </Collapse>
      </li>
    </React.Fragment>
  )
}

function NavGroup({ items, className, location, level }) {
  const currentChild = location && location.pathname && location.pathname.split('/')[level+1]

  return (
    <ul
      className={
        className
          ? `${className} list-unstyled`
          : `${styles.menuList} list-unstyled`
      }
    >
      {items.map(item => {
        const selected = currentChild === item.permalink.split('/')[level+1]

        return item._children.length > 0 ? (
          <NavItem item={item} location={location} key={item.permalink} level={level+1}/>
        ) : (
          <Link key={item.permalink} className={`${styles.menuItemLink} ${selected ? styles.fontBold:''}`} to={item.permalink}>
            {item["nav_title"] || item.title}
          </Link>
        )
      })}
    </ul>
  )
}

function LeftNav({ data, location }) {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleNavClick = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <nav className={`${styles.menu}`}>
      <NavGroup
        level={1}
        location={location}
        items={data._children}
        className={`${styles.menuList} ${styles.list}`}
      />
    </nav>
  )

  return (
    <>
      <div className={`d-block d-lg-none`}>
        <Button onClick={handleNavClick} aria-expanded={mobileOpen}></Button>
        <Collapse in={mobileOpen}>
          <Nav aria-label="platforms" className={`${styles.sidebarContent}`}>
            {drawer}
          </Nav>
        </Collapse>
      </div>
      <div className={`d-none d-lg-block ${styles.aside}`}>
        <Nav
          aria-label={"platforms"}
          className={`${styles.sidebar} d-flex flex-column`}
        >
          <div className={`${styles.sidebarContent}`}>{drawer}</div>
        </Nav>
      </div>
    </>
  )
}

LeftNav.Item = NavItem
LeftNav.Group = NavGroup

export default LeftNav
